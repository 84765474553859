<template>
    <router-view></router-view>
</template>
<script>
export default {
    data: () => ({
        forICTDongnai: process.env.VUE_APP_IS_FOR_DONGNAI === "true"
    }),
    mounted() {
        const prefix_title = this.forICTDongnai ? "" : "[a' Meeting] - "
        document.title = `${prefix_title}${this.$t("common.appTitle")}`;
    }
}
</script>
<style scoped>
.view-container {
    margin-left: 260px;
}
.view-container-responsive {
    margin-left: 40px;
}
</style>